import { useCeilingGridId, useGetCeilingGridsQuery, useProjectId } from '@consigli/hooks';
import { CeilingType } from '@consigli/types';

import { PackageList } from '@/organisms/workspace-tabs/package-list';

export const CeilingGridPackage = () => {
  const projectId = useProjectId();
  const ceilingGridId = useCeilingGridId();

  const { data: ceilingGrids = [] } = useGetCeilingGridsQuery({
    projectId,
    type: CeilingType.CEILING_GRID,
  });

  return <PackageList packages={ceilingGrids} id={ceilingGridId} />;
};
