import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { documentPackageSchema } from './document-package';
import { projectSchema } from './project';
export var PipelineStatus;
(function (PipelineStatus) {
    PipelineStatus["STARTED"] = "STARTED";
})(PipelineStatus || (PipelineStatus = {}));
export var PackageType;
(function (PackageType) {
    PackageType["DOCUMENT_PACKAGE"] = "DOCUMENT_PACKAGE";
    PackageType["CEILING_GRID"] = "CEILING_GRID";
})(PackageType || (PackageType = {}));
export var PipelineName;
(function (PipelineName) {
    PipelineName["DEFAULT"] = "DEFAULT";
    PipelineName["VALIDATION_ONLY"] = "VALIDATION_ONLY";
    PipelineName["PRE_PARSING_ONLY"] = "PRE_PARSING_ONLY";
    PipelineName["PARSING_ONLY"] = "PARSING_ONLY";
    PipelineName["POST_PARSING_ONLY"] = "POST_PARSING_ONLY";
    PipelineName["TEXT_PROCESSING_ONLY"] = "TEXT_PROCESSING_ONLY";
    PipelineName["VALIDATION_TO_POST_PARSING"] = "VALIDATION_TO_POST_PARSING";
    PipelineName["IFC_EXTRACTION_ONLY"] = "IFC_EXTRACTION_ONLY";
    PipelineName["IFC_PROCESSING_ONLY"] = "IFC_PROCESSING_ONLY";
})(PipelineName || (PipelineName = {}));
export const pipelineNameSchema = z.nativeEnum(PipelineName);
export const startPipelineResponseSchema = z
    .object({
    status: z.nativeEnum(PipelineStatus),
    pipeline_job_id: z.string().uuid(),
})
    .strict()
    .transform(mapToCamelCase);
export const startPipelineRequestSchema = z
    .object({
    pipelineName: pipelineNameSchema,
    projectId: projectSchema.innerType().shape.id,
    packageId: documentPackageSchema.innerType().shape.id,
    secondaryPackageId: documentPackageSchema.innerType().shape.id.optional(),
    notify: z.boolean().optional(),
    packageType: z.nativeEnum(PackageType),
})
    .strict()
    .transform(mapToCamelCase);
