import { FC } from 'react';

import { Point, pointsToPath } from '../types';

interface WindowProps {
  edgePts: Point[];
}

export const Window: FC<WindowProps> = ({ edgePts }) => {
  return (
    <g>
      <path d={pointsToPath(edgePts)} fill="#87ceeb" stroke="#000000" strokeWidth="0.03" />
    </g>
  );
};
