import { useMemo } from 'react';
import { useGetCeilingGridFilesQuery } from '../api';
export const useCeilingGridFiles = (params) => {
    const { data: filesResponse, isLoading, isFetching, isError, error, } = useGetCeilingGridFilesQuery(params);
    return useMemo(() => {
        const totalCount = filesResponse?.count || 0;
        const files = filesResponse?.results || [];
        const next = filesResponse?.next;
        const previous = filesResponse?.previous;
        return {
            files,
            totalCount,
            next,
            previous,
            isLoading,
            isFetching,
            isError,
            error,
        };
    }, [filesResponse, isLoading, isFetching, isError, error]);
};
