import { createContext, useContext, useState, useRef, FC, ReactNode, useMemo } from 'react';

import {
  CompleteCeilingJSON,
  FloorData,
  RoomData,
  DoorData,
  WindowData,
  ViewBox,
  Point,
  RoomType,
} from './types';

interface CeilingViewerContextProps {
  completeCeilingJSON: CompleteCeilingJSON | undefined;
  setCompleteCeilingJSON: React.Dispatch<React.SetStateAction<CompleteCeilingJSON | undefined>>;
  allFloors: FloorData[];
  setAllFloors: React.Dispatch<React.SetStateAction<FloorData[]>>;
  selectedFloors: FloorData[];
  setSelectedFloors: React.Dispatch<React.SetStateAction<FloorData[]>>;
  activeFloorId: string | undefined;
  setActiveFloorId: React.Dispatch<React.SetStateAction<string | undefined>>;
  rooms: RoomData;
  selectedRooms: RoomData;
  setSelectedRooms: React.Dispatch<React.SetStateAction<RoomData>>;
  doors: DoorData;
  windows: WindowData;
  viewBox: ViewBox;
  setViewBox: React.Dispatch<React.SetStateAction<ViewBox>>;
  initialViewBox: ViewBox;
  setInitialViewBox: React.Dispatch<React.SetStateAction<ViewBox>>;
  isDragging: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  dragStart: Point | null;
  setDragStart: React.Dispatch<React.SetStateAction<Point | null>>;
  svgRef: React.RefObject<SVGSVGElement>;
  showEditMissingCeilingView: boolean;
  setShowEditMissingCeilingView: React.Dispatch<React.SetStateAction<boolean>>;
  showEditDesignCriteriaView: boolean;
  setShowEditDesignCriteriaView: React.Dispatch<React.SetStateAction<boolean>>;
  roomsWithMissingCeiling: RoomType[];
  // designCriteria: DesignCriteria[];
  // setDesignCriteria: React.Dispatch<React.SetStateAction<DesignCriteria[]>>;
  isFirstFloor: boolean;
  isLastFloor: boolean;
  createdCeilingGridId: string | null;
  setCreatedCeilingGridId: React.Dispatch<React.SetStateAction<string | null>>;
  isIFCApproved: boolean;
  setIsIFCApproved: React.Dispatch<React.SetStateAction<boolean>>;
  isProcessingIFC: boolean;
  setIsProcessingIFC: React.Dispatch<React.SetStateAction<boolean>>;
}

const CeilingViewerContext = createContext<CeilingViewerContextProps | undefined>(undefined);

export const CeilingViewerProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [completeCeilingJSON, setCompleteCeilingJSON] = useState<CompleteCeilingJSON>();
  const [allFloors, setAllFloors] = useState<FloorData[]>([]);
  const [selectedFloors, setSelectedFloors] = useState<FloorData[]>([]);
  const [selectedRooms, setSelectedRooms] = useState<RoomData>({});
  const [activeFloorId, setActiveFloorId] = useState<string>();
  // const [designCriteria, setDesignCriteria] = useState<DesignCriteria[]>([]);
  const [createdCeilingGridId, setCreatedCeilingGridId] = useState<string | null>(null);
  const [isIFCApproved, setIsIFCApproved] = useState(false);
  const [isProcessingIFC, setIsProcessingIFC] = useState(false);

  const [showEditMissingCeilingView, setShowEditMissingCeilingView] = useState(false);
  const [showEditDesignCriteriaView, setShowEditDesignCriteriaView] = useState(false);

  const [viewBox, setViewBox] = useState<ViewBox>({ x: 0, y: 0, width: 100, height: 100 });
  const [initialViewBox, setInitialViewBox] = useState<ViewBox>({
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState<Point | null>(null);

  const svgRef = useRef<SVGSVGElement>(null);

  const activeFloor = useMemo(() => {
    return allFloors?.find((floor) => floor.uuid === activeFloorId);
  }, [allFloors, activeFloorId]);

  const rooms = useMemo(() => activeFloor?.rooms || {}, [activeFloor]);
  const doors = activeFloor?.doors || {};
  const windows = activeFloor?.windows || {};

  const roomsWithMissingCeiling = useMemo(() => {
    return Object.values(rooms).filter(
      (room) => room.lowerCeilingZ === null && !room.ignoreCeiling,
    );
  }, [rooms]);

  const isFirstFloor = useMemo(() => {
    return selectedFloors.length > 0 && selectedFloors[0].uuid === activeFloorId;
  }, [selectedFloors, activeFloorId]);

  const isLastFloor = useMemo(() => {
    return (
      selectedFloors.length > 0 && selectedFloors[selectedFloors.length - 1].uuid === activeFloorId
    );
  }, [selectedFloors, activeFloorId]);

  return (
    <CeilingViewerContext.Provider
      value={{
        completeCeilingJSON,
        setCompleteCeilingJSON,
        allFloors,
        setAllFloors,
        selectedFloors,
        setSelectedFloors,
        activeFloorId,
        setActiveFloorId,
        rooms,
        selectedRooms,
        setSelectedRooms,
        doors,
        windows,
        viewBox,
        setViewBox,
        initialViewBox,
        setInitialViewBox,
        isDragging,
        setIsDragging,
        dragStart,
        setDragStart,
        svgRef,
        showEditMissingCeilingView,
        setShowEditMissingCeilingView,
        showEditDesignCriteriaView,
        setShowEditDesignCriteriaView,
        roomsWithMissingCeiling,
        // designCriteria,
        // setDesignCriteria,
        isFirstFloor,
        isLastFloor,
        createdCeilingGridId,
        setCreatedCeilingGridId,
        isIFCApproved,
        setIsIFCApproved,
        isProcessingIFC,
        setIsProcessingIFC,
      }}
    >
      {children}
    </CeilingViewerContext.Provider>
  );
};

export const useCeilingViewerContext = () => {
  const context = useContext(CeilingViewerContext);
  if (!context) {
    throw new Error('useCeilingViewer must be used within a CeilingViewerProvider');
  }
  return context;
};
