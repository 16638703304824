import { useCallback, useEffect } from 'react';

import { useCeilingViewerContext } from '../ceiling-viewer-context';

export const useFloorSwitcher = () => {
  const {
    selectedFloors,
    activeFloorId,
    setActiveFloorId,
    setViewBox,
    setInitialViewBox,
    setSelectedRooms,
  } = useCeilingViewerContext();

  const switchFloor = useCallback(
    (floorId: string) => {
      const floor = selectedFloors.find((f) => f.uuid === floorId);
      if (floor) {
        const allPoints = Object.values(floor.rooms).flatMap((room) => room.polygonPts.exterior);
        const minX = Math.min(...allPoints.map((pt) => pt.x));
        const maxX = Math.max(...allPoints.map((pt) => pt.x));
        const minY = Math.min(...allPoints.map((pt) => pt.y));
        const maxY = Math.max(...allPoints.map((pt) => pt.y));

        const padding = 20;
        const aspectRatio = 1.75;

        const paddedMinX = minX - padding;
        const paddedMaxX = maxX + padding;
        const paddedMinY = minY - padding;
        const paddedMaxY = maxY + padding;

        const width = paddedMaxX - paddedMinX;
        const height = width / aspectRatio;

        const initialViewBox = {
          x: paddedMinX,
          y: paddedMinY - (height - (paddedMaxY - paddedMinY)) / 2,
          width: width,
          height: height,
        };

        setViewBox(initialViewBox);
        setInitialViewBox(initialViewBox);
      }
    },
    [selectedFloors, setInitialViewBox, setViewBox],
  );

  const handleFloorChange = useCallback(
    (id: string) => {
      setActiveFloorId(id);
      switchFloor(id);
      setSelectedRooms({});
    },
    [setActiveFloorId, setSelectedRooms, switchFloor],
  );

  const goToPreviousFloor = useCallback(() => {
    const currentIndex = selectedFloors.findIndex((floor) => floor.uuid === activeFloorId);
    if (currentIndex > 0) {
      handleFloorChange(selectedFloors[currentIndex - 1].uuid);
    }
  }, [activeFloorId, handleFloorChange, selectedFloors]);

  const goToNextFloor = useCallback(() => {
    const currentIndex = selectedFloors.findIndex((floor) => floor.uuid === activeFloorId);
    if (currentIndex < selectedFloors.length - 1) {
      handleFloorChange(selectedFloors[currentIndex + 1].uuid);
    }
  }, [activeFloorId, handleFloorChange, selectedFloors]);

  useEffect(() => {
    if (selectedFloors.length > 0 && !activeFloorId) {
      handleFloorChange(selectedFloors[0].uuid);
    }
  }, [activeFloorId, handleFloorChange, selectedFloors]);

  return { handleFloorChange, goToPreviousFloor, goToNextFloor };
};
