import { useServiceCategory, useServiceName } from '@consigli/hooks';
import { ServiceCategory } from '@consigli/types';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import mascot from '@/assets/images/consigli-mascot.webp';

export const WaitingForAnalysis: FC = () => {
  const { t } = useTranslation();
  const serviceName = useServiceName();
  const serviceCategory = useServiceCategory();

  const message = useMemo(() => {
    if (serviceCategory === ServiceCategory.LAYOUT_OPTIMIZATION) {
      return t('dashboard.waiting-for-analysis-optimization', {
        serviceName: t(`services.${serviceName}`),
      });
    } else {
      return t('dashboard.waiting-for-analysis-insight');
    }
  }, [serviceCategory, serviceName, t]);

  return (
    <div className="flex items-center justify-center">
      <p className="w-[400px] font-semibold text-day-neutral-dark">{message}</p>
      <img src={mascot} alt="Consigli mascot" className="h-[80vh]"></img>
    </div>
  );
};
