import { Button } from '@consigli/facade';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCloudCheck } from 'react-icons/bs';

import { useCeilingViewerContext } from '../ceiling-viewer-context';
import { RoomType } from '../types';

export const MissingCeiling: FC = () => {
  const { t } = useTranslation();
  const { roomsWithMissingCeiling, setShowEditMissingCeilingView } = useCeilingViewerContext();

  return (
    <div className="flex flex-col bg-white shadow-md rounded-md px-6 py-4 justify-center">
      <h2 className="text-xl font-bold">{t('package-creation.ceiling-grid.missing-ceiling')}</h2>
      {roomsWithMissingCeiling.length !== 0 && (
        <p className="text-sm mt-2">
          {t('package-creation.ceiling-grid.rooms-missing-ceiling')}:{' '}
          {roomsWithMissingCeiling.length}
        </p>
      )}
      <div className="border border-day-light-1 border-dashed rounded mt-2 p-2">
        {roomsWithMissingCeiling.length === 0 && (
          <div className="flex flex-col justify-center items-center p-2 text-center">
            <BsCloudCheck size={30} />
            <p className="text-sm">{t('package-creation.ceiling-grid.all-rooms-approved')}</p>
          </div>
        )}
        <div className="flex flex-wrap gap-2">
          {Object.values(roomsWithMissingCeiling).map((room: RoomType) => (
            <div
              key={room.roomId}
              className="bg-day-light-1 rounded-sm py-1 px-2 flex justify-between items-center"
            >
              <p className="text-sm">
                {room.roomId} - {room.roomCategory ?? t('package-creation.ceiling-grid.unassigned')}
              </p>
            </div>
          ))}
        </div>
      </div>
      {Object.keys(roomsWithMissingCeiling).length > 0 && (
        <Button
          primary
          rounded
          className="mt-2"
          onClick={() => setShowEditMissingCeilingView(true)}
        >
          {t('package-creation.ceiling-grid.edit')}
        </Button>
      )}
    </div>
  );
};
