import { useCallback } from 'react';

import { useCeilingViewerContext } from '../ceiling-viewer-context';

export const useDrag = () => {
  const { isDragging, setIsDragging, dragStart, setDragStart, setViewBox } =
    useCeilingViewerContext();

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      if (event.button === 2) {
        setIsDragging(true);
        setDragStart({ x: event.clientX, y: event.clientY });
      }
    },
    [setIsDragging, setDragStart],
  );

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      if (isDragging && dragStart) {
        const dx = event.clientX - dragStart.x;
        const dy = event.clientY - dragStart.y;
        setViewBox((prev) => ({
          ...prev,
          x: prev.x - dx * (prev.width / 500),
          y: prev.y + dy * (prev.height / 500),
        }));
        setDragStart({ x: event.clientX, y: event.clientY });
      }
    },
    [isDragging, dragStart, setViewBox, setDragStart],
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setDragStart(null);
  }, [setIsDragging, setDragStart]);

  return { handleMouseDown, handleMouseMove, handleMouseUp };
};
