import { mapToCamelCase } from '@consigli/utils';
import { z } from 'zod';
import { fileSchema } from './file';
import { getPaginationResponseSchema } from './pagination';
export const ceilingGridResultSchema = z
    .object({
    id: z.string().uuid(),
    ceiling_grid_id: z.string().uuid(),
    file: fileSchema,
    updated_at: z.string().datetime(),
    created_at: z.string().datetime(),
})
    .strict()
    .transform(mapToCamelCase);
export const getCeilingGridResultsResponseSchema = getPaginationResponseSchema(ceilingGridResultSchema);
export const ceilingGridResultsResponseSchema = z
    .array(ceilingGridResultSchema)
    .transform(mapToCamelCase);
export const createCeilingGridResultRequestSchema = z
    .object({
    file_id: z.string().uuid(),
})
    .strict()
    .transform(mapToCamelCase);
export const getCeilingGridResultsRequestSchema = z
    .object({
    project_id: z.number().int(),
    ceiling_grid_id: z.string().uuid(),
    page: z.union([z.number().int(), z.literal('all')]),
    page_size: z.number().int().nullish(),
    search: z.string().nullish(),
})
    .strict()
    .transform(mapToCamelCase);
