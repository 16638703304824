import { UseStepContext } from '@consigli/facade';
import { PipelineName } from '@consigli/types';
import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CeilingGridResponse } from './ceiling-wizard';
import { UploadWithIFCCheck } from './upload-with-ifc-check';
import { IterationName } from '../components/iteration-name';
import { RequiredOptimizationFiles } from '../components/required-file-types';

interface UploadFilesStepProps {
  localFiles: File[];
  setLocalFiles: React.Dispatch<React.SetStateAction<File[]>>;
  onCeilingGridCreation: () => CeilingGridResponse;
  startAnalysis: (packageId: string, pipelineName: PipelineName) => Promise<void>;
  useStepContext: UseStepContext;
}

export const UploadFilesStep: FC<UploadFilesStepProps> = ({
  localFiles,
  setLocalFiles,
  onCeilingGridCreation,
  startAnalysis,
  useStepContext,
}) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext();
  const [showMessagePanel, setShowMessagePanel] = useState(true);

  return (
    <div className="w-full max-w-md mx-auto flex flex-col items-center space-y-6">
      {showMessagePanel && <RequiredOptimizationFiles setShowMessagePanel={setShowMessagePanel} />}
      <form className="w-full" onSubmit={handleSubmit(onCeilingGridCreation)}>
        <div className="w-full">
          <IterationName
            validateName={(value) =>
              value ? true : `${t('package-creation.description-required')}*`
            }
            label={t('package-creation.description')}
            errorMessage={`${t('package-creation.description-required')}*`}
          />
        </div>
        <div className="w-full">
          <UploadWithIFCCheck
            localFiles={localFiles}
            setLocalFiles={setLocalFiles}
            useStepContext={useStepContext}
            onCeilingGridCreation={onCeilingGridCreation}
            startAnalysis={startAnalysis}
          />
        </div>
      </form>
    </div>
  );
};
