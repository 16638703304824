import { Button, Position, UseStepContext } from '@consigli/facade';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import { TbEdit } from 'react-icons/tb';

import { useCeilingViewerContext } from '../ceiling-viewer-context';
import { MissingCeiling } from './missing-ceiling';
import { MissingRoomType } from './missing-room-type';
import { useFloorSwitcher } from '../hooks/use-floor-switcher';

interface CeilingEditorProps {
  useStepContext: UseStepContext;
}

export const CeilingEditor: FC<CeilingEditorProps> = ({ useStepContext }) => {
  const {
    activeFloorId,
    // setShowEditDesignCriteriaView,
    isFirstFloor,
    isLastFloor,
    selectedFloors,
    allFloors,
    // designCriteria,
    setCompleteCeilingJSON,
  } = useCeilingViewerContext();
  const { goToPreviousFloor, goToNextFloor } = useFloorSwitcher();
  const { previous } = useStepContext();
  const { t } = useTranslation();

  // temp function to download json instead of processing
  const downloadJSON = useCallback((jsonData: object, filename: string) => {
    const dataStr =
      'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(jsonData, null, 2));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', filename);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }, []);

  const handlePreviousClick = useCallback(() => {
    if (isFirstFloor) {
      previous();
    } else {
      goToPreviousFloor();
    }
  }, [goToPreviousFloor, isFirstFloor, previous]);

  const handleNextClick = useCallback(() => {
    if (isLastFloor) {
      setCompleteCeilingJSON((prev) => {
        const updatedCeilingJSON = {
          ...prev,
          floors: allFloors,
          // designCriteria: designCriteria,
        };
        downloadJSON(updatedCeilingJSON, 'ceiling-grid.json');
        return updatedCeilingJSON;
      });
    } else {
      goToNextFloor();
    }
  }, [allFloors, downloadJSON, goToNextFloor, isLastFloor, setCompleteCeilingJSON]);

  const activeFloorName = useMemo(() => {
    return selectedFloors.find((floor) => floor.uuid === activeFloorId)?.floorName;
  }, [selectedFloors, activeFloorId]);

  return (
    <div className="mx-6 flex flex-col w-[400px] h-[80vh] overflow-y-auto">
      <div className="flex mb-4">
        <HiOutlineOfficeBuilding size={46} className="text-day-neutral-subtle mr-2" />
        <div>
          <p className="text-sm">{t('package-creation.ceiling-grid.verify-and-fill')}</p>
          <h1 className="text-xl font-semibold">{activeFloorName}</h1>
        </div>
      </div>
      <div className="flex flex-col space-y-4">
        <MissingRoomType />
        <MissingCeiling />
      </div>
      <div className="flex flex-col items-center mt-4">
        <Button
          secondary
          icon={TbEdit}
          rounded
          className="w-fit"
          disabled={true}
          // onClick={() => setShowEditDesignCriteriaView(true)}
        >
          {t('package-creation.ceiling-grid.change-design-criteria')}
        </Button>
        <div className="flex space-x-4 mt-2">
          <Button
            primary
            rounded
            className="w-fit"
            icon={FaArrowLeft}
            iconColor="white"
            onClick={handlePreviousClick}
          >
            {isFirstFloor
              ? t('package-creation.ceiling-grid.select-floors')
              : t('package-creation.previous')}
          </Button>
          <Button
            primary
            rounded
            className="w-fit"
            icon={FaArrowRight}
            iconColor="white"
            iconPosition={Position.RIGHT}
            onClick={handleNextClick}
          >
            {isLastFloor ? t('package-creation.ceiling-grid.process') : t('package-creation.next')}
          </Button>
        </div>
      </div>
    </div>
  );
};
