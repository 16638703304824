import { FileUpload, UseStepContext } from '@consigli/facade';
import { PipelineName } from '@consigli/types';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CeilingGridResponse } from './ceiling-wizard';
import { CheckIFCButton } from './check-ifc-button';

interface UploadWithIFCCheckProps {
  localFiles: File[];
  setLocalFiles: Dispatch<SetStateAction<File[]>>;
  useStepContext: UseStepContext;
  infoMessage?: string | JSX.Element;
  onCeilingGridCreation: () => CeilingGridResponse;
  startAnalysis: (packageId: string, pipelineName: PipelineName) => Promise<void>;
}

export const UploadWithIFCCheck = ({
  localFiles,
  setLocalFiles,
  useStepContext,
  infoMessage,
  onCeilingGridCreation,
  startAnalysis,
}: UploadWithIFCCheckProps) => {
  const { t } = useTranslation();
  const [showMessagePanel, setShowMessagePanel] = useState(true);

  return (
    <FileUpload
      files={localFiles}
      setFiles={setLocalFiles}
      dropAreaLabel={t('document-upload.drag-n-drop-here')}
      dropAreaClassName="mt-4 h-48 w-full"
      fileButtonLabel={t('document-upload.browse-files')}
      folderButtonLabel={t('document-upload.browse-folders')}
      heading={t('package-creation.upload-files')}
      uploadListLabel={t('document-upload.files')}
      infoMessage={infoMessage}
      showMessagePanel={showMessagePanel}
      setShowMessagePanel={setShowMessagePanel}
    >
      <CheckIFCButton
        localFiles={localFiles}
        context={useStepContext}
        onCeilingGridCreation={onCeilingGridCreation}
        startAnalysis={startAnalysis}
      />
    </FileUpload>
  );
};
