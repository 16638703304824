import { ServiceId, ServiceName } from '@consigli/types';

export enum Route {
  ROOT = '/',
  PROJECTS = 'projects',
  SERVICES = 'services',
  PACKAGES = 'packages',
  DASHBOARD = 'dashboard',
  UPLOAD = 'upload',
  SEARCH = 'search',
  FINDINGS = 'findings',
  DOCUMENTS = 'documents',
  CATEGORIZATION = 'categorization',
  PROPCHAT = 'propchat',
  DUE_DILIGENCE = 'due-diligence',
  TENANT_REQUIREMENTS = 'tenant-requirements',
  TENDER_DOCUMENTS = 'tender-documents',
  MANAGEMENT_OPERATIONS_MAINTENANCE = 'management-operations-maintenance',
  TENANT_SPECIFICATION = 'tenant-specification',
  OFFER = 'offer',
  CEILING_GRID = 'ceiling-grid',
  CEILING_PLAN = 'ceiling-plan',
  FLOOR_PLAN = 'floor-plan',
  PLANT_ROOM = 'plant-room',
  SPACE_ANALYSIS = 'space-analysis',
  PARKING = 'parking',
  REPORTS = 'reports',
  STRUCTURAL = 'structural',
  NEW_PROJECT = 'new-project',
  RESULTS = 'results',
  INPUT = 'input',
  CREATE = 'create',
  INVITE_USER = 'invite-user',
  USER_MANAGEMENT = 'user-management',
  TERMS = 'terms',
  STEEL_FRAMES = 'steel-frames',
  CONCRETE_COLUMNS = 'concrete-columns',
}

export const ServiceIdIndexValue: Partial<Record<ServiceName, ServiceId>> = {
  [ServiceName.DUE_DILIGENCE]: ServiceId.DUE_DILIGENCE,
  [ServiceName.TENANT_REQUIREMENTS]: ServiceId.TENANT_REQUIREMENTS,
  [ServiceName.MANAGEMENT_OPERATIONS_MAINTENANCE]: ServiceId.MANAGEMENT_OPERATIONS_MAINTENANCE,
  [ServiceName.TENDER_DOCUMENTS]: ServiceId.TENDER_DOCUMENTS,
  [ServiceName.OFFER]: ServiceId.OFFER,
  [ServiceName.TENANT_SPECIFICATION]: ServiceId.TENANT_SPECIFICATION,
};
export const ServiceIdNameIndex: Partial<Record<ServiceId, ServiceName>> = {
  [ServiceId.DUE_DILIGENCE]: ServiceName.DUE_DILIGENCE,
  [ServiceId.TENANT_REQUIREMENTS]: ServiceName.TENANT_REQUIREMENTS,
  [ServiceId.MANAGEMENT_OPERATIONS_MAINTENANCE]: ServiceName.MANAGEMENT_OPERATIONS_MAINTENANCE,
  [ServiceId.TENDER_DOCUMENTS]: ServiceName.TENDER_DOCUMENTS,
  [ServiceId.OFFER]: ServiceName.OFFER,
  [ServiceId.TENANT_SPECIFICATION]: ServiceName.TENANT_SPECIFICATION,
};

export const DashboardRoutes = {
  [ServiceName.TENDER_DOCUMENTS]: 'tender-documents',
  [ServiceName.OFFER]: 'offer',
  [ServiceName.TENANT_REQUIREMENTS]: 'tenant-requirements',
  [ServiceName.TENANT_SPECIFICATION]: 'tenant-specification',
  [ServiceName.DUE_DILIGENCE]: 'due-diligence',
  [ServiceName.MANAGEMENT_OPERATIONS_MAINTENANCE]: 'management-operations-maintenance',
  [ServiceName.FLOOR_PLAN]: 'floor-plan',
  [ServiceName.CEILING_GRID]: 'ceiling-grid',
  [ServiceName.CEILING_PLAN]: 'ceiling-plan',
  [ServiceName.PLANT_ROOM]: 'plant-room',
  [ServiceName.SPACE_ANALYSIS]: 'space-analysis',
  [ServiceName.STRUCTURAL]: 'structural',
};
