import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const CeilingViewerLegend: FC = () => {
  const { t } = useTranslation();

  const legendItems = [
    { color: '#ffdfdf', label: t('package-creation.ceiling-grid.missing-room-type') },
    { color: '#dfffff', label: t('package-creation.ceiling-grid.missing-ceiling') },
    { color: '#f9dcf9', label: t('package-creation.ceiling-grid.missing-both') },
  ];

  return (
    <div className="absolute top-4 left-4 p-2 rounded">
      {legendItems.map((item) => (
        <div key={item.label} className="flex items-center mb-1">
          <div
            className="w-4 h-4 mr-2 border"
            style={{ backgroundColor: item.color, borderColor: '#969896', borderWidth: '0.5px' }}
          />
          <span className="text-sm text-day-neutral-dark">{item.label}</span>
        </div>
      ))}
    </div>
  );
};
