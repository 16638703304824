import { Spinner } from '@consigli/facade';
import {
  useProjectId,
  useServiceName,
  useCeilingGridId,
  useGetCeilingGridResultsQuery,
} from '@consigli/hooks';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Route } from '@/routes';

// This component is used to navigate to the correct ceiling grid page when switching packages
// If the ceiling has results, it will navigate to the results page
// If not, it will navigate to the documents page
export const CeilingGridNavigationWrapper = () => {
  const projectId = useProjectId();
  const ceilingGridId = useCeilingGridId();
  const serviceName = useServiceName();
  const navigate = useNavigate();

  const { data: ceilingGridResults } = useGetCeilingGridResultsQuery({
    projectId,
    ceilingGridId,
    page: 'all',
  });

  const hasResults = useMemo(() => {
    return ceilingGridResults ? ceilingGridResults.results.length > 0 : undefined;
  }, [ceilingGridResults]);

  const navRoute = useMemo(() => {
    return `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${ceilingGridId}/${hasResults ? Route.RESULTS : Route.INPUT}`;
  }, [projectId, serviceName, ceilingGridId, hasResults]);

  useEffect(() => {
    if (hasResults !== undefined) {
      navigate(navRoute, { replace: true });
    }
  }, [hasResults, navRoute, navigate]);

  return <Spinner className="m-auto" size="large" />;
};
