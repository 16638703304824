import { FC } from 'react';

import { useCeilingViewerContext } from './ceiling-viewer-context';
import { CeilingViewerLegend } from './components/ceiling-viewer-legend';
import { ControlPanel } from './components/control-panel';
import { Door } from './components/door';
import { Room } from './components/room';
import { Window } from './components/window';
import { useDrag } from './hooks/use-drag';
import { useZoom } from './hooks/use-zoom';

export const CeilingViewer: FC = () => {
  const { rooms, doors, windows, viewBox, svgRef, selectedRooms } = useCeilingViewerContext();
  const { handleMouseDown, handleMouseMove, handleMouseUp } = useDrag();
  useZoom();

  return (
    <div className="relative w-full h-full">
      {rooms && (
        <svg
          ref={svgRef}
          viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`}
          width="100%"
          height="100%"
          className="cursor-grab"
          transform="scale(1, -1)"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onContextMenu={(e) => e.preventDefault()}
        >
          {Object.values(rooms).map((room) => (
            <Room
              key={room.roomId}
              roomId={room.roomId}
              exteriorPts={room.polygonPts.exterior}
              roomType={room.roomCategory}
              lowerCeilingZ={room.lowerCeilingZ}
              ignoreCeiling={room.ignoreCeiling}
              centroid={room.centroid}
              selected={!!selectedRooms[room.roomId]}
            />
          ))}
          {Object.values(doors).map((door) => (
            <Door key={door.id} edgePts={door.polygonPts.exterior} />
          ))}
          {Object.values(windows).map((window) => (
            <Window key={window.id} edgePts={window.polygonPts.exterior} />
          ))}
        </svg>
      )}
      <CeilingViewerLegend />
      <ControlPanel />
    </div>
  );
};
