import { useEffect } from 'react';

import { useCeilingViewerContext } from '../ceiling-viewer-context';

export const useZoom = () => {
  const { setViewBox, svgRef } = useCeilingViewerContext();

  useEffect(() => {
    const svg = svgRef.current;
    if (!svg) return;

    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const scale = event.deltaY > 0 ? 1.05 : 0.95;
      const { clientX, clientY } = event;

      const pt = svg.createSVGPoint();
      pt.x = clientX;
      pt.y = clientY;
      const cursorPt = pt.matrixTransform(svg.getScreenCTM()?.inverse());

      setViewBox((prev) => {
        const newWidth = prev.width * scale;
        const newHeight = prev.height * scale;
        const dx = (cursorPt.x - prev.x) * (scale - 1);
        const dy = (cursorPt.y - prev.y) * (scale - 1);

        return {
          x: prev.x - dx,
          y: prev.y - dy,
          width: newWidth,
          height: newHeight,
        };
      });
    };

    svg.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      svg.removeEventListener('wheel', handleWheel);
    };
  }, [setViewBox, svgRef]);
};
