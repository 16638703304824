import { Button, Checkbox, Position, UseStepContext } from '@consigli/facade';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowRight } from 'react-icons/bs';

import { useCeilingViewerContext } from '@/components/features/ceiling-viewer/ceiling-viewer-context';
import { FloorData } from '@/components/features/ceiling-viewer/types';

interface SelectFloorsStepProps {
  useStepContext: UseStepContext;
}

export const SelectFloorsStep: FC<SelectFloorsStepProps> = ({ useStepContext }) => {
  const { allFloors = [], selectedFloors = [], setSelectedFloors } = useCeilingViewerContext();
  const { t } = useTranslation();
  const { next } = useStepContext();

  const handleCheckboxChange = (floor: FloorData) => {
    if (selectedFloors.some((f) => f.uuid === floor.uuid)) {
      setSelectedFloors(selectedFloors.filter((f) => f.uuid !== floor.uuid));
    } else {
      setSelectedFloors([...selectedFloors, floor]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectedFloors.length === allFloors.length) {
      setSelectedFloors([]);
    } else {
      setSelectedFloors(allFloors);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center sm:w-[35rem]">
        <div className="mb-4 w-full flex flex-col items-start ml-16">
          <h1 className="text-xl font-semibold my-6">
            {t('package-creation.ceiling-grid.select-floors')}
          </h1>
          <Checkbox
            checked={selectedFloors.length === allFloors.length}
            label={t('package-creation.ceiling-grid.select-all')}
            id="select-all"
            onChange={handleSelectAllChange}
          />
          <div className="space-y-2">
            {allFloors.map((floor) => (
              <Checkbox
                key={floor.uuid}
                checked={selectedFloors.some((f) => f.uuid === floor.uuid)}
                label={floor.floorName}
                id={floor.uuid}
                onChange={() => handleCheckboxChange(floor)}
              />
            ))}
          </div>
        </div>
        <Button
          primary
          rounded
          onClick={() => next()}
          className="self-center mt-6"
          disabled={selectedFloors.length === 0}
          icon={BsArrowRight}
          iconPosition={Position.RIGHT}
          iconColor="white"
        >
          {t('package-creation.ceiling-grid.confirm-selection')}
        </Button>
      </div>
    </div>
  );
};
