import { useSteps, StepLayoutChildProps, Step } from '@consigli/facade';
import { CeilingGrid, CeilingType } from '@consigli/types';
import { FC, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { CeilingViewerContainer } from '@/components/features/ceiling-viewer/container';

import { CeilingWizardLayout } from './ceiling-wizard-layout';
import { SelectFloorsStep } from './select-floors-step';
import { UploadFilesStep } from './upload-files';
import { useCreatePackageUtil } from '../hooks/useCreatePackage';
import { useUploadProgress } from '../hooks/useUploadProgress';

export type CeilingGridResponse = Promise<CeilingGrid | undefined>;

export const CeilingWizard = () => {
  const { t } = useTranslation();

  const methods = useForm<FieldValues>({
    defaultValues: {
      iterationName: '',
      inputType: '',
    },
  });
  const { getValues } = methods;

  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [targetCounter, setTargetCounter] = useState(0);
  const [currentCounter, setCurrentCounter] = useState(0);
  const { onAnalyze: onPackageCreation, startAnalysis } = useCreatePackageUtil({
    setCurrentCounter,
    setTargetCounter,
  });

  const onCeilingGridCreation = async (): CeilingGridResponse => {
    try {
      const response = await onPackageCreation(localFiles, getValues(), CeilingType.CEILING_GRID);
      if (response && response.type === CeilingType.CEILING_GRID) {
        return response;
      }
    } catch (error) {
      toast.error(t(`package-creation.failed-analyzed`));
      return undefined;
    }
  };

  const { isUploading, progress } = useUploadProgress(currentCounter, targetCounter);

  const { withStep, StepIndicator, StepOutlet, useStepContext } = useSteps(
    (step: (name: string, component: FC<StepLayoutChildProps>) => Step) => [
      step(t('createproject.uploadfiles'), () => (
        <FormProvider {...methods}>
          <UploadFilesStep
            localFiles={localFiles}
            setLocalFiles={setLocalFiles}
            onCeilingGridCreation={onCeilingGridCreation}
            startAnalysis={startAnalysis}
            useStepContext={useStepContext}
          />
        </FormProvider>
      )),
      step(t('package-creation.ceiling-grid.select-floors'), () => (
        <SelectFloorsStep useStepContext={useStepContext} />
      )),
      step(t('package-creation.ceiling-grid.floor-configuration'), () => (
        <CeilingViewerContainer useStepContext={useStepContext} />
      )),
    ],
  );

  const stepsRenderer = withStep(() => (
    <>
      <StepIndicator />
      <StepOutlet />
    </>
  ));

  return (
    <CeilingWizardLayout
      stepsRenderer={stepsRenderer}
      isUploading={isUploading}
      progress={progress}
    />
  );
};
