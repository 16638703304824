import { UseStepContext } from '@consigli/facade';
import { FC } from 'react';

import { CenteredLayout } from '@/layouts/centered-content';
import { FluidLayout } from '@/layouts/fluid-content';

import { CeilingViewer } from './ceiling-viewer';
import { useCeilingViewerContext } from './ceiling-viewer-context';
import { CeilingEditor } from './components/ceiling-editor';
import { EditCeilingZValues } from './components/edit-ceiling-z-value';
// import { EditDesignCriteria } from './components/edit-design-criteria';
import { FloorPicker } from './components/floor-picker';

interface CeilingViewerContainerProps {
  useStepContext: UseStepContext;
}

export const CeilingViewerContainer: FC<CeilingViewerContainerProps> = ({ useStepContext }) => {
  const { showEditDesignCriteriaView, showEditMissingCeilingView } = useCeilingViewerContext();

  return (
    <FluidLayout>
      <CenteredLayout>
        {showEditMissingCeilingView ? (
          <EditCeilingZValues />
        ) : showEditDesignCriteriaView ? (
          //<EditDesignCriteria />
          <></>
        ) : (
          <>
            <FloorPicker />
            <div className="flex h-full m-6">
              <CeilingEditor useStepContext={useStepContext} />
              <div className="flex-1">
                <CeilingViewer />
              </div>
            </div>
          </>
        )}
      </CenteredLayout>
    </FluidLayout>
  );
};
