import { useParams } from 'react-router-dom';
/**
 * @returns the ceilingGridId from the current route (url)
 */
export const useCeilingGridId = () => {
    const { ceilingGridId } = useParams();
    if (ceilingGridId == null) {
        throw new Error(`useCeilingGridId called with invalid :ceilingGridId (${ceilingGridId})`);
    }
    return ceilingGridId;
};
