import { FC } from 'react';

import { Point, pointsToPath } from '../types';

interface DoorProps {
  edgePts: Point[];
}

export const Door: FC<DoorProps> = ({ edgePts }) => {
  return (
    <g>
      <path d={pointsToPath(edgePts)} fill="#00008B" />
    </g>
  );
};
