import { FC, useCallback } from 'react';

import { useCeilingViewerContext } from '../ceiling-viewer-context';
import { Point, pointsToPath } from '../types';

interface RoomProps {
  roomId: number;
  exteriorPts: Point[];
  roomType: string | null;
  lowerCeilingZ: number | null;
  ignoreCeiling: boolean;
  centroid: Point;
  selected: boolean;
}

export const Room: FC<RoomProps> = ({
  exteriorPts,
  roomId,
  roomType,
  lowerCeilingZ,
  ignoreCeiling,
  centroid,
  selected,
}) => {
  const { viewBox, rooms, setSelectedRooms } = useCeilingViewerContext();

  const roomState = selected
    ? 'selected'
    : ignoreCeiling
      ? 'ignoreCeiling'
      : !roomType && lowerCeilingZ === null
        ? 'missingBoth'
        : !roomType
          ? 'missingRoomType'
          : lowerCeilingZ === null
            ? 'missingCeiling'
            : 'normal';

  const fillColor =
    roomState === 'selected'
      ? '#ffdc78'
      : roomState === 'ignoreCeiling'
        ? '#e3e1e1'
        : roomState === 'missingBoth'
          ? '#f9dcf9'
          : roomState === 'missingRoomType'
            ? '#ffdfdf'
            : roomState === 'missingCeiling'
              ? '#dfffff'
              : '#edede0';

  const fontColor = roomState === 'selected' ? '#a3a38f' : '#d0d0c5';
  const fontSize = Math.min(viewBox.width, viewBox.height) / 40;

  const handleRoomClick = useCallback(() => {
    setSelectedRooms((prev) => {
      const newSelectedRooms = { ...prev };
      if (newSelectedRooms[roomId]) {
        delete newSelectedRooms[roomId];
      } else {
        newSelectedRooms[roomId] = rooms[roomId];
      }
      return newSelectedRooms;
    });
  }, [roomId, rooms, setSelectedRooms]);

  return (
    <g onClick={handleRoomClick} className="hover:opacity-75">
      <path d={pointsToPath(exteriorPts)} fill={fillColor} stroke="#969896" strokeWidth="0.03" />
      <text
        x={centroid.x}
        y={-centroid.y}
        fontSize={fontSize}
        fontWeight="bold"
        fill={fontColor}
        transform="scale(1, -1)"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ userSelect: 'none', pointerEvents: 'none' }}
      >
        {roomId}
      </text>
    </g>
  );
};
